<template>
	<div class="educateUndergo" id="educateUndergo">
		<div class="p-l-10">
			<my-title title="教育经历" add @add="openEdit('add')" />
		</div>
		<div v-loading="loading" class="educateUndergo_box br-10" :class="{'bg_f7':checked==index}" v-for="(item,index) in educateData"
			@mouseenter="mouseenter(index)" @mouseleave="checked=null">
			<!-- 操作按钮 -->
			<div class="educateUndergo_operate flex just-end" v-if="checked==index">
				<div class="flex ali-cen" @click="openEdit(index)">
					<img src="@/static/image/edit.png" alt="">
					<span>编辑</span>
				</div>
				<div class="flex ali-cen m-l-15" @click="deleteItem(index)">
					<img src="@/static/image/trash.png" alt="">
					<span>删除</span>
				</div>
			</div>
			<!-- 主要信息 -->
			<div class="educateUndergo_info m-t-10">
				<div class="c-3">
					<span>学历：{{item.record}}</span>
					<span class="m-l-40">在校时间：{{item.school_start}}-{{item.school_end}}</span>
					<span class="m-l-40">学校名称：{{item.school_title}}</span>
					<span class="m-l-40">专业：{{item.major}}</span>
				</div>
				<div class="m-t-15">
					<ul class="c-6 p-l-30">
						<li>主修课程</li>
						<li>{{item.curriculum}}</li>
					</ul>
				</div>
				<!-- <div class="m-t-15">
					<ul class="c-6 p-l-30">
						<li>校内荣誉/奖项</li>
						<li v-html="item.honor"></li>
					</ul>
				</div>
				<div class="m-t-15">
					<ul class="c-6 p-l-30">
						<li>校内职务</li>
						<li v-html="item.job"></li>
					</ul>
				</div> -->
				<div class="m-t-15">
					<ul class="c-6 p-l-30">
						<li>培训经历</li>
						<li v-html="item.train"></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default {
		components: {
			myTitle
		},
		props: {
			educateData: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				checked: null,
				loading:false
			}
		},
		methods: {
			// 鼠标移入
			mouseenter(index) {
				this.checked = index;
			},
			openEdit(index) {
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('educateUndergo', index);
			},
			// 删除某一项
			deleteItem(index) {
				this.$confirm('确认删除此条教育经历吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
					this.loading=true;
					const _list = JSON.parse(JSON.stringify(this.educateData));
					_list.splice(index, 1);
					const _parent = this.$parent.$parent.$parent.$parent.$parent;
					const res = _parent.handleEdit('education_json', _list);
					if(!res) return
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					this.loading=false;
				}).catch((err)=>{
					
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.educateUndergo_box {
		cursor: pointer;
		margin-top: 6px;
		box-sizing: border-box;
		padding: 10px 10px 15px 10px;

		.educateUndergo_operate {
			div {
				color: #81D8D1;
				font-size: 14px;

				img {
					width: 18px;
					height: 18px;
					margin-right: 5px;
				}
			}
		}

		.educateUndergo_info {
			font-size: 14px;

			ul {
				li:first-child {
					list-style: disc;
				}

				li:not(:first-child) {
					margin-top: 5px;
				}
			}
		}
	}
</style>