import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index.vue'
import LoginView from "@/views/login/index.vue"
import {
	Message
} from 'element-ui'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'login',
		component: LoginView
	},
	{
		path: '/home',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// console.log(to);
	if (to.path == '/home') {
		const token = localStorage.getItem('token')
		if (token) {
			next()
		} else {
			Message({
				message: '请先登录',
				type: 'error'
			})
			next({
				name: 'login'
			})
		}
	} else {
		next()
	}
})

export default router