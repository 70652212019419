<template>
	<div class="jobUndergo" id="projectUndergo">
		<div class="p-l-10">
			<my-title title="项目经历" add @add="openEdit('add')" />
		</div>
		<div class="jobUndergo_box m-t-10 br-10" :class="{'bg_f7':checked==index}" v-for="(item,index) in projecteData" @mouseenter="mouseenter(index)" @mouseleave="checked=null" v-loading="loading">
			<!-- 操作按钮 -->
			<div class="educateUndergo_operate flex just-end p-tb-10" v-if="checked==index">
				<div class="flex ali-cen" @click="openEdit(index)">
					<img src="@/static/image/edit.png" alt="">
					<span>编辑</span>
				</div>
				<div class="flex ali-cen m-l-15" @click="deleteItem(index)">
					<img src="@/static/image/trash.png" alt="">
					<span>删除</span>
				</div>
			</div>
			<!-- 主要内容 -->
			<div class="jobUndergo_info">
				<div class="f-bw">
					<div class="c-3">
						<span>{{item.title}}</span>
						<span class="m-l-40">{{item.role}}</span>
					 </div>
					 <div class="ts-14">{{item.start}}-{{item.end}}</div>
				</div>
				<div class="jobUndergo_content flex ts-14 c-6">
					<div>内容：</div>
					<div class="jobUndergo_contentText">
						<div v-html="item.content"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default{
		components:{
			myTitle
		},
		props:{
			projecteData:{
				type:Array,
				default:()=>[]
			}
		},
		data() {
			return{
				checked:null,
				loading:false
			}
		},
		methods:{
			// 鼠标移入
			mouseenter(index){
				this.checked=index;
			},
			openEdit(index) {
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('projectUndergo', index);
			},
			// 删除某一项
			deleteItem(index) {
				this.$confirm('确认删除此条项目经历吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
					this.loading=true;
					const _list = JSON.parse(JSON.stringify(this.projecteData));
					_list.splice(index, 1);
					const _parent = this.$parent.$parent.$parent.$parent.$parent;
					const res = _parent.handleEdit('project_json', _list);
					if(!res) return
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					this.loading=false;
				}).catch((err)=>{
					
				})
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.jobUndergo_box{
		padding: 0 10px 10px 10px;
		box-sizing: border-box;
		cursor: pointer;
		.educateUndergo_operate{
			div{
				color: #81D8D1;
				font-size: 14px;
				img{
					width: 18px;
					height: 18px;
					margin-right: 5px;
				}
			}
		}
		.jobUndergo_info{
			padding-right: 83px;
			.jobUndergo_content{
				flex-wrap: nowrap;
				margin-top: 13px;
				.jobUndergo_contentText{
					width: 90%;
					div:not(:first-child){
						margin-top: 10px;
					}
				}
			}
		}
	}
	
</style>