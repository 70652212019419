export const practiceUndergoRules = {
	company_title: {
		required: true,
		message: '请填写公司名称',
		trigger: 'change'
	},
	position: {
		required: true,
		message: '请填写实习岗位',
		trigger: 'change'
	},
	sjd: {
		required: true,
		message: '请选择时间段',
		trigger: 'change'
	},
	content: {
		required: true,
		message: '请填写实习工作内容',
		trigger: 'change'
	},
}