<template>
	<div class="JobIntentionAdd" id="JobIntention" v-loading="loading">
		<div class="p-l-10">
			<my-title title="在校情况" />
		</div>
		<div class="JobIntentionAdd_box br-10 psr">
			<el-form ref="form" :model="form" label-position="top" :rules="rules">
				<el-row>
					<el-col :span="18">
						<el-form-item label="校内荣誉/奖项" prop="honor">
							<el-input v-model="form.honor" placeholder="请填写校内荣誉/奖项"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="校内职务" prop="jobs">
							<el-input v-model="form.jobs" placeholder="请填写校内职务"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="form_bot flex just-end">
				<div class="form_btn f-cen noBg" @click="cancle">
					<span>取消</span>
				</div>
				<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
					<span>完成</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default {
		components: {
			myTitle
		},
		props: {
			schoolData: {
				type: Object,
				default: () => {}
			}
		},
		watch: {
			schoolData: {
				handler(val) {
					this.form = JSON.parse(JSON.stringify(val));
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return {
				form: {},
				loading:false,
				rules:{
					honor: {
						required: true,
						message: '请填写校内荣誉/奖项',
						trigger: 'change'
					},
					jobs: {
						required: true,
						message: '请填写校内职务',
						trigger: 'change'
					}
				}
			}
		},
		methods: {
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			// 提交
			async submit(){
				const valid = await this.$refs.form.validate();
				if(!valid) return
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				const res = await _parent.handleEdit('school_json',this.form);
				if(res){
					this.loading=false;
					this.cancle();
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.JobIntentionAdd_box {
		width: 78%;
		background-color: #F7F7F7;
		margin-top: 6px;
		padding: 20px;
		// padding-top: 15px;

		.el-col {
			/deep/.el-form-item {
				margin-bottom: 18px;

				.el-form-item__label {
					padding-bottom: 0 !important;
					line-height: 30px;
					color: #333333;
				}
			}

			/deep/.el-input__inner {
				border: none;
				border-radius: 6px;
				height: 35px;
			}

			/deep/.el-date-editor {
				width: auto;
			}

			/deep/.el-textarea__inner {
				border: none;
				border-radius: 6px;
			}
		}

		.form_bot {
			bottom: 28px;
			right: 20px;

			.form_btn {
				width: 66px;
				height: 24px;
				border-radius: 18px;
				font-size: 12px;
				cursor: pointer;
			}

			.hasBg {
				color: #ffffff;
				background-color: #81D8D1;
			}

			.noBg {
				color: #81D8D1;
				background-color: #ffffff;
			}
		}
	}
</style>