export const JobIntentionRules = {
	type: {
		required: true,
		message: '请选择求职类型',
		trigger: 'change'
	},
	state: {
		required: true,
		message: '请选择求职状态',
		trigger: 'change'
	},
	position: {
		required: true,
		message: '请选择期望岗位',
		trigger: 'change'
	},
	city: {
		required: true,
		message: '请选择目标城市',
		trigger: 'change'
	},
	money: {
		required: true,
		message: '请填写期望薪资',
		trigger: 'change'
	}
}