import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import config from "./config.js"
// create an axios instance

Vue.prototype.axios = axios
axios.defaults.timeout = 30000
const service = axios.create({
  baseURL:config.baseURL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.withCredentials = true
	const token = localStorage.getItem('token');
    if (token) {
      config.headers['token'] = token
      // config.headers['refreshToken'] = store.state.user.refreshToken
      // withCredentials: true
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject("请联系管理员")
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // 判断token
    if (!(response.headers.token == "" || response.headers.token == null || response.headers.token == undefined)) {
      // store.commit("user/settoken", response.headers.token);
    }
    const res = response.data
	return new Promise((resolve,reject)=>{
		if (res.code == 401) {
		  router.push("/login")
		}
		if (res.code == 0) {
		  Message({
		    message: res.msg || 'Error',
		    type: 'error',
		    duration: 5 * 1000
		  })
		  reject(res)
		  // return res
		}
		else {
			resolve(res)
		  // return res
		}
	})
  },
  error => {
	  // debugger
	  console.log('error: ',error);
    if (error.code === "ERR_NETWORK") {
      Message({
        message: "数据异常，请联系管理员",
        type: 'error',
        duration: 5 * 1000
      })
    }else {
      // router.push("/404")
    Message({
      message: "后台异常，请联系管理员",
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject("请联系管理员")
    }

  }
)
export default service

