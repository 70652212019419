<template>
	<div class="educateUndergoAdd" id="educateUndergo">
		<div class="p-l-10">
			<my-title title="教育经历" add />
		</div>
		<div class="educateUndergoAdd_box br-10" v-loading="loading">
			<div class="educateUndergoAdd_form">
				<el-form ref="form" :model="form" label-position="top" :rules="rules">
					<el-row>
						<el-col :span="10" :offset="1">
							<el-form-item label="学历" prop="record">
							    <el-select v-model="form.record" placeholder="请填写">
							        <el-option
							          v-for="item in eduList"
							          :key="item.value"
							          :label="item.label"
							          :value="item.label">
							        </el-option>
							      </el-select>
							  </el-form-item>
						</el-col>
						<el-col :span="10" :offset="2">
							<el-form-item label="学校名称" prop="school_title">
							   <el-input v-model="form.school_title" placeholder="请填写学校名称"></el-input>
							  </el-form-item>
						</el-col>
						<el-col :span="10" :offset="1">
							<el-form-item label="在校时间" prop="zxsj">
							 <el-date-picker
									value-format="yyyy-MM"
							       v-model="form.zxsj"
							       type="monthrange"
							       range-separator="-"
							       start-placeholder="开始时间"
							       end-placeholder="结束时间">
							     </el-date-picker>
							  </el-form-item>
						</el-col>
						<el-col :span="10" :offset="2">
							<el-form-item label="专业" prop="major">
								<el-select v-model="form.major" placeholder="请填写专业">
								    <el-option
								      v-for="item in majorList"
								      :key="item.id"
								      :label="item.title"
								      :value="item.title">
								    </el-option>
								  </el-select>
							  </el-form-item>
						</el-col>
						<el-col :span="22" :offset="1">
							<el-form-item label="主修课程">
							    <el-input v-model="form.curriculum" placeholder="请填写主修课程"></el-input>
							  </el-form-item>
						</el-col>
						<!-- <el-col :span="22" :offset="1">
							<el-form-item label="校内荣誉/奖项">
							    <el-input v-model="form.honor" placeholder="请填写校内荣誉/奖项"></el-input>
							  </el-form-item>
						</el-col>
						<el-col :span="22" :offset="1">
							<el-form-item label="校内职务">
							    <el-input v-model="form.job" placeholder="请填写校内职务"></el-input>
							  </el-form-item>
						</el-col> -->
						<el-col :span="22" :offset="1">
							<el-form-item label="培训经历">
								<Editor style="width: 100%;" v-model="form.train" />
							  </el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="form_bot flex just-end p-r-20">
					<div class="form_btn f-cen noBg" @click="cancle">
						<span>取消</span>
					</div>
					<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
						<span>完成</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	import { educationList,majorList } from "@/api/api.js"
	import Editor from "@/components/Editor.vue"
	import {educateUndergoRules} from "./common.js"
	export default{
		components:{
			myTitle,
			Editor
		},
		props:{
			educateData:{
				type:Array,
				default:()=>[]
			}
		},
		watch:{
			educateData:{
				handler(val){
					this.getEducationList();
					this.getMajorList();
					if(val && Array.isArray(val) && val.length === 0) return
					const _parent = this.$parent.$parent.$parent.$parent.$parent;
					if(_parent.undergoIndex=='add') return
					this.form = val[_parent.undergoIndex];
					const _form = this.form;
					this.$set(this.form,'zxsj',[_form.school_start,_form.school_end]);
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return{
				form:{},
				list:[],
				eduList:[],
				majorList:[],
				loading:false,
				rules:educateUndergoRules
			}
		},
		methods:{
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			// 获取学历列表
			getEducationList(){
				educationList().then(res=>{
					const _obj = res.data;
					let _arr = [];
					for (let key in _obj) {
						_arr.push({
							label: _obj[key],
							value: key
						})
					}
					this.$set(this,'eduList',_arr);
					// this.eduList=_arr;
				})
			},
			// 获取专业列表
			getMajorList(){
				majorList({page:1,list_rows:9999}).then(res=>{
					const _arr = res.data.data;
					this.majorList = _arr;
				})
			},
			async submit(){
				const valid = await this.$refs.form.validate();
				if(!valid) return
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				this.form.school_start=this.form.zxsj[0];
				this.form.school_end=this.form.zxsj[1];
				const res = await _parent.handleEdit('education_json',this.form);
				if(!res) return
				this.loading=false;
				this.$message({
					message: '保存成功',
					type: 'success'
				})
				this.cancle();
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.educateUndergoAdd_box{
		width: 100%;
		margin-top: 6px;
		background-color: #F7F7F7;
		padding-top: 20px;
		padding-bottom: 17px;
		.el-col{
			/deep/.el-form-item{
				margin-bottom: 18px;
				.el-form-item__label{
					padding-bottom: 0 !important;
					line-height: 30px;
					color: #333333;
				}
			}
			/deep/.el-input__inner{
				border: none;
				border-radius: 6px;
				height: 35px;
			}
			/deep/.el-date-editor{
				width: auto;
			}
			/deep/.el-textarea__inner{
				border: none;
				border-radius: 6px;
			}
		}
		.educateUndergoAdd_form{
			width: 78%;
		}
		.form_bot{
			.form_btn{
				cursor: pointer;
				width: 66px;
				height: 24px;
				border-radius: 18px;
				font-size: 12px;
			}
			.hasBg{
				color: #ffffff;
				background-color: #81D8D1;
			}
			.noBg{
				color: #81D8D1;
				background-color: #ffffff;
			}
		}
	}
</style>