import request from '@/config/request.js'

// 会员登录 /api/user/login
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

 // 发送验证码 /api/ems/send
export function send(data) {
  return request({
    url: '/api/sms/send',
    method: 'post',
    data
  })
}

// 手机验证码登录 /api/user/mobilelogin
export function mobilelogin(data) {
  return request({
    url: '/api/user/mobilelogin',
    method: 'post',
    data
  })
}

 // 退出登录 /api/user/logout
 export function logout(data) {
   return request({
     url: '/api/user/logout',
     method: 'post',
     data
   })
 }