import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  userInfo:{
		  token:'430a6830-cb95-4c42-bb01-4f54055ec2dd'
	  }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
