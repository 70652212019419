<template>
	<div class="myTitle c-3 psr p-l-15 f-bw">
		<span>{{title}}</span>
		<div class="myTitle_add flex ali-cen" v-if="add" @click="$emit('add')">
			<img src="@/static/image/resumeAdd.png" alt="">
			<span class="page-c">添加</span>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			title:{
				type:String,
				default:''
			},
			add:{
				type:Boolean,
				default:false
			}
		}
	}
</script>

<style lang="less" scoped>
	.myTitle{
		font-size: 20px;
		font-weight: 500;
		.myTitle_add{
			cursor: pointer;
			img{
				width: 18px;
				height: 18px;
				margin-right: 5px;
			}
			span{
				font-size: 14px;
			}
		}
	}
	.myTitle::after{
		content: '';
		width: 3px;
		height: 15px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #81D8D1;
	}
</style>