<template>
	<div class="JobIntentionAdd" id="JobIntention" v-loading="loading">
		<div class="p-l-10">
			<my-title title="求职意向" />
		</div>
		<div class="JobIntentionAdd_box br-10 psr">
			<el-form ref="form" :model="form" label-position="top" :rules="rules">
				<el-row>
					<el-col :span="10" :offset="1">
						<el-form-item label="求职类型" prop="type">
							<el-select v-model="form.type" placeholder="请填写" @change="typeChange">
								<el-option v-for="item in typeList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="求职状态" prop="state">
							<el-select v-model="form.state" placeholder="请填写">
								<el-option v-for="item in statusList" :key="item.id" :label="item.intention_status"
									:value="item.intention_status">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="1">
						<el-form-item label="期望岗位" prop="position">
							<el-select clearable filterable remote reserve-keyword :remote-method="getPostList" v-model="form.position" placeholder="请选择">
								<el-option v-for="item in postList" :key="item.id" :label="item.title"
									:value="item.title">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="目标城市" prop="city">
							<el-select clearable filterable remote reserve-keyword :remote-method="getCityData" v-model="form.city" placeholder="请选择">
								<el-option v-for="item in cityList" :key="item.id" :label="item.name"
									:value="item.name">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="1">
						<el-form-item label="期望薪资" prop="money">
							<el-input v-model="form.money" placeholder="请填写期望薪资"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="form_bot flex just-end psa">
				<div class="form_btn f-cen noBg" @click="cancle">
					<span>取消</span>
				</div>
				<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
					<span>完成</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	import {
		typeArr
	} from "../../common.js"
	import {
		getJobIntentionList,
		getPositionList,
		getCityList
	} from "@/api/api.js"
	import {JobIntentionRules} from "./common.js"
	export default {
		components: {
			myTitle
		},
		props: {
			intentionData: {
				type: Object,
				default: () => {}
			}
		},
		watch: {
			intentionData: {
				handler(val) {
					this.form = JSON.parse(JSON.stringify(val));
					const _form = this.form;
					if(_form.type){
						this.getStatus(_form.type);
					}
					if(_form.position){
						this.getPostList(_form.position)
					}
					if(_form.city){
						this.getCityData(_form.city)
					}
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return {
				form: {},
				// 求职类型列表
				typeList: typeArr,
				// 求职状态列表
				statusList: [],
				// 岗位列表
				postList:[],
				// 城市列表
				cityList:[],
				loading:false,
				rules:JobIntentionRules
			}
		},
		methods: {
			typeChange(e) {
				this.$set(this.form, 'state', null)
				this.getStatus(e);
			},
			// 获取求职状态
			getStatus(type) {
				getJobIntentionList({
					type: type
				}).then(res => {
					const _arr = res.data;
					this.statusList = _arr;
				})
			},
			// 远程搜索期望岗位
			getPostList(e){
				console.log('e: ',e);
				getPositionList({title:e}).then(res=>{
					const _arr = res.data;
					this.postList = _arr;
				})
			},
			// 远程搜索城市
			getCityData(e){
				getCityList({title:e}).then(res=>{
					const _arr = res.data;
					this.cityList=_arr;
				})
			},
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			// 提交
			async submit(){
				const valid = await this.$refs.form.validate();
				if(!valid) return
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				const res = await _parent.handleEdit('job_intention_json',this.form);
				if(res){
					this.loading=false;
					this.cancle();
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.JobIntentionAdd_box {
		width: 78%;
		background-color: #F7F7F7;
		margin-top: 6px;
		padding-top: 15px;

		.el-col {
			/deep/.el-form-item {
				margin-bottom: 18px;

				.el-form-item__label {
					padding-bottom: 0 !important;
					line-height: 30px;
					color: #333333;
				}
			}

			/deep/.el-input__inner {
				border: none;
				border-radius: 6px;
				height: 35px;
			}

			/deep/.el-date-editor {
				width: auto;
			}

			/deep/.el-textarea__inner {
				border: none;
				border-radius: 6px;
			}
		}

		.form_bot {
			bottom: 28px;
			right: 20px;

			.form_btn {
				width: 66px;
				height: 24px;
				border-radius: 18px;
				font-size: 12px;
				cursor: pointer;
			}

			.hasBg {
				color: #ffffff;
				background-color: #81D8D1;
			}

			.noBg {
				color: #81D8D1;
				background-color: #ffffff;
			}
		}
	}
</style>