export const directoryArr =[
	{
		name:'个人信息',
		id:'#userInfo'
	},
	{
		name:'求职意向',
		id:'#JobIntention'
	},
	{
		name:'教育经历',
		id:'#educateUndergo'
	},
	{
		name:'工作经历',
		id:'#jobUndergo'
	},
	{
		name:'实习经历',
		id:'#practiceUndergo'
	},
	{
		name:'项目经历',
		id:'#projectUndergo'
	},
	{
		name:'专业技能及荣誉',
		id:'#mySkill'
	},
	{
		name:'自我评价',
		id:'#selfEvaluate'
	}
]

export const sexArr = [
	{
		label:'男',
		value:'1'
	},
	{
		label:'女',
		value:'2'
	}
]

export const typeArr = [
	{
		label:'全职',
		value:'full'
	},
	{
		label:'兼职',
		value:'part'
	},
	{
		label:'实习',
		value:'practice'
	},
	{
		label:'应届',
		value:'graduates'
	}
]

export const resumeUserArr = ['username','sex','birthday_year','birthday_month','mobile','email','address','birthday']