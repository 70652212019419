<template>
	<div class="ResumeManage bg_f b-s br-20">
		<div class="ResumeManage_title flex ali-cen p-l-15 c-3">
			<span>简历管理</span>
		</div>
		<div class="ResumeManage_box p-lr-15" v-loading="loading">
			<div class="ResumeManage_item p-tb-20 f-bw bl-b" v-for="(item,index) in list">
				<div class="ResumeManage_itemLeft flex">
					<img class="m-r-10" src="@/static/image/jianlilogo.png" alt="">
					<div class="flex">
						<span class="c-3">{{item.title}}</span>
						<span class="c-9">{{item.create_time_text}}</span>
					</div>
				</div>
				<el-popover placement="right" width="90" trigger="click">
					<div class="">
						<div class="popover_item ts-14 c-3 bl-b p-b-10" @click="edit(item)">
							编辑
						</div>
						<div class="popover_item ts-14 c-3 p-t-10" @click="handleDel(index)">
							删除
						</div>
					</div>
					<img slot="reference" class="ResumeManage_dot" src="@/static/image/dot3.png" alt="">
				</el-popover>
			</div>
		</div>
	</div>
</template>

<script>
	import {delResume} from "@/api/api.js"
	export default{
		props:{
			list:{
				type:Array,
				default:()=>[]
			}
		},
		data() {
			return{
				show:false,
				loading:false
			}
		},
		methods:{
			// 编辑
			edit(item){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.changeResume(item.id);
			},
			// 删除
			handleDel(index){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				const _item = this.list[index];
				if(_item.id==_parent.resumeId){
					this.$message({
						message: '正在编辑的简历不可删除',
						type: 'warning'
					})
					return
				}
				this.loading=true;
				delResume({resume_id:_item.id}).then(res=>{
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					_parent.getResumeList(true);
					this.loading=false;
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	
	.popover_item{
		text-align: center;
		border-color: #F7F7F7;
		cursor: pointer;
	}
	.ResumeManage {
		display: inline-block;
		vertical-align: top;
		width: 20%;
	}

	.ResumeManage_title {
		height: 50px;
		font-weight: 800;
		background-image: url('/src/static/image/bgimg3.png');
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 20px 20px 0 0;
	}

	.ResumeManage_box {
		padding-bottom: 4px;

		.ResumeManage_item {
			border-color: #FAFAFA;
			
			.ResumeManage_itemLeft {
				flex-wrap: nowrap;

				img {
					width: 35px;
					height: 35px;
				}

				div {
					flex-direction: column;

					span:first-child {
						font-size: 14px;
						margin-bottom: 2px;
					}

					span:nth-child(2) {
						font-size: 12px;
					}
				}
			}

			.ResumeManage_dot {
				cursor: pointer;
				width: 14px;
				height: 14px;
			}
		}
	}
</style>