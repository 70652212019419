export const projectUndergoRules = {
	title: {
		required: true,
		message: '请填写项目名称',
		trigger: 'change'
	},
	role: {
		required: true,
		message: '请填写项目角色',
		trigger: 'change'
	},
	sjd: {
		required: true,
		message: '请选择时间段',
		trigger: 'change'
	},
	content: {
		required: true,
		message: '请填写项目内容',
		trigger: 'change'
	}
}