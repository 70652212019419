import request from '@/config/request.js'

// 获取简历详情
export function getResumeDetail(params) {
  return request({
    url: '/api/resume/getResumeDetail',
    method: 'get',
    params
  })
}

// 求职意向/求职类型 /api/resume/getJobIntentionList
export function getJobIntentionList(params) {
  return request({
    url: '/api/resume/getJobIntentionList',
    method: 'get',
    params
  })
}

// 求职意向/岗位 /api/resume/getPositionList
export function getPositionList(params) {
  return request({
    url: '/api/resume/getPositionList',
    method: 'get',
    params
  })
}

// 求职意向/城市 /api/resume/getCityList
export function getCityList(params) {
  return request({
    url: '/api/resume/getCityList',
    method: 'get',
    params
  })
}

// 修改简历 /api/resume/editResume
export function editResume(params) {
  return request({
    url: '/api/resume/editResume',
    method: 'get',
    params
  })
}

// 最高学历列表 /api/user_info/educationList
export function educationList(params) {
  return request({
    url: '/api/user_info/educationList',
    method: 'get',
    params
  })
}

// 专业列表 /api/user_info/majorList
export function majorList(params) {
  return request({
    url: '/api/user_info/majorList',
    method: 'get',
    params
  })
}

 // 获取用户信息 /api/user/userInfo
 export function userInfo(params) {
   return request({
     url: '/api/user/userInfo',
     method: 'get',
     params
   })
 }
 
 // 我的简历列表 /api/resume/getMyResumeList
 export function getMyResumeList(params) {
   return request({
     url: '/api/resume/getMyResumeList',
     method: 'get',
     params
   })
 }
 
 // 获取用户注册信息 /api/user_info/getUserInfo
 export function getUserInfo(params) {
   return request({
     url: '/api/user_info/getUserInfo',
     method: 'get',
     params
   })
 }
 
 // 删除简历 /api/resume/delResume
 export function delResume(params) {
   return request({
     url: '/api/resume/delResume',
     method: 'get',
     params
   })
 }
 
// 上传文件 /api/common/upload
export function upload(data) {
  return request({
    url: '/api/common/upload',
    method: 'post',
    data
  })
}