export const educateUndergoRules = {
	record: {
		required: true,
		message: '请选择学历',
		trigger: 'change'
	},
	school_title:{
		required: true,
		message: '请填写学校名称',
		trigger: 'change'
	},
	zxsj:{
		required: true,
		message: '请填写在校时间',
		trigger: 'change'
	},
	major:{
		required: true,
		message: '请填写专业',
		trigger: 'change'
	},
}