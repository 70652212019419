<template>
	<el-row class="psr">
		<el-col :span="8" :offset="4">
			<div class="header flex ali-cen">
				<img src="@/static/image/headerLogo.png" alt="">
				<span class="tc-w m-l-10">职场教练</span>
			</div>
		</el-col>
		<el-col :span="8" :offset="4">
			<div @click="loginOut" style="height: 60px; cursor: pointer;" class="flex ali-cen tc-w">
				<img class="out_img" src="@/static/image/out.png" alt="">
				<span class="tb">退出登录</span>
			</div>
		</el-col>
	</el-row>
</template>

<script>
	import {logout} from "@/api/login.js"
	export default{
		methods:{
			loginOut(){
				this.$confirm('确认退出登录吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(()=>{
					logout().then(res=>{
						this.$message({
							type:'success',
							message:'退出成功',
						})
						localStorage.removeItem('userInfo');
						localStorage.removeItem('token');
						this.$router.push('/')
					})
				}).catch((err)=>{
					
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.header{
		height: 60px;
		img{
			width: 30px;
			height: 30px;
		}
		span{
			font-weight: 800;
		}
	}
	.out_img{
		margin-right: 5px;
		width: 25px;
		height: 25px;
	}
</style>