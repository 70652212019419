<template>
	<div class="Directory bg_f b-s br-20 m-r-10">
		<div class="Directory_title flex ali-cen p-l-15">
			<span>简历目录</span>
		</div>
		<ul class="p-lr-15">
			<a id="test" v-for="(item,index) in dataInfo" :href="item.id">
				<li :class="{'bl-b':index<dataInfo.length-1}" class="p-tb-15">{{item.name}}</li>
			</a>
		</ul>
	</div>
</template>

<script>
	export default{
		props:{
			dataInfo:{
				type:Array,
				default:[]
			}
		}
	}
</script>

<style lang="less" scoped>
	.Directory{
		width: 12.5%;
		display: inline-block;
		vertical-align: top;
		a{
			text-decoration: none;
		}
		.Directory_title{
			background-image: url('/src/static/image/bgimg1.png');
			background-repeat: no-repeat;
			background-size: cover;
			height: 50px;
			color: #333333FF;
			font-weight: 800;
			font-size: 16px;
			border-radius: 20px 20px 0 0;
		}
		
		li{
			font-size: 14px;
			color: #333333FF;
			border-color: #FAFAFAFF;
			cursor: pointer;
		}
	}
</style>