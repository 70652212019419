<template>
	<div class="mySkill" id="mySkill">
		<div class="p-l-10">
			<my-title title="专业技能及荣誉" />
		</div>
		<div class="mySkill_box m-t-10 br-10 psr" @mouseenter="checked=true" @mouseleave="checked=false" :class="{'bg_f7':checked}">
			<div class="m-b-15 ts-14 c-3">
				<div v-html="skillData"></div>
			</div>
			<!-- 操作按钮 -->
			<div class="mySkill_operate flex psa" v-show="checked">
				<div class="flex ali-cen" @click="openEdit">
					<img src="@/static/image/edit.png" alt="">
					<span>编辑</span>
				</div>
				<!-- <div class="flex ali-cen m-l-15">
					<img src="@/static/image/trash.png" alt="">
					<span>删除</span>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default{
		components:{
			myTitle
		},
		props:{
			skillData:{
				type:String,
				default:''
			}
		},
		data() {
			return{
				checked:false
			}
		},
		methods:{
			openEdit(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('mySkill');
			}
		}
	}
</script>

<style lang="less" scoped>
	.mySkill_box{
		padding: 10px 10px 5px 10px;
		box-sizing: border-box;
		cursor: pointer;
	}
	.mySkill_operate{
		top: 10px;
		right: 10px;
		div{
			color: #81D8D1;
			font-size: 14px;
			img{
				width: 18px;
				height: 18px;
				margin-right: 5px;
			}
		}
	}
</style>