<template>
	<div class="selfEvaluateAdd" id="selfEvaluate">
		<div class="p-l-10">
			<my-title title="自我评价" />
		</div>
		<div class="selfEvaluateAdd_box br-10" v-loading="loading">
			<div class="selfEvaluateAdd_form">
				<el-form ref="form" :model="form" label-position="top">
					<el-row>
						<el-col :span="22" :offset="1">
							<el-form-item label="内容">
							    <!-- <el-input rows="5" type="textarea" v-model="form.nr" placeholder="请填写自我评价"></el-input> -->
								<Editor v-model="form.content" />
							  </el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="form_bot flex just-end p-r-20">
					<div class="form_btn f-cen noBg" @click="cancle">
						<span>取消</span>
					</div>
					<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
						<span>完成</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	import Editor from "@/components/Editor.vue"
	export default{
		components:{
			myTitle,
			Editor
		},
		props:{
			selfData:{
				type:String,
				default:''
			}
		},
		watch:{
			selfData:{
				handler(val){
					this.$set(this.form,'content',val);
				},
				deep:true,
				immediate: true
			}
		},
		data() {
			return{
				form:{},
				loading:false
			}
		},
		methods:{
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			// 提交
			async submit(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				const res = await _parent.handleEdit('self_text',this.form.content);
				if(res){
					this.loading=false;
					this.cancle();
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.selfEvaluateAdd_box{
		width: 100%;
		margin-top: 10px;
		background-color: #F7F7F7;
		padding-top: 20px;
		padding-bottom: 15px;
		.selfEvaluateAdd_form{
			width: 78%;
			.el-col{
				/deep/.el-form-item{
					margin-bottom: 18px;
					.el-form-item__label{
						padding-bottom: 0 !important;
						line-height: 30px;
						color: #333333;
					}
				}
				/deep/.el-input__inner{
					border: none;
					border-radius: 6px;
					height: 35px;
				}
				/deep/.el-date-editor{
					width: auto;
				}
				/deep/.el-textarea__inner{
					border: none;
					border-radius: 6px;
				}
			}
			.form_bot{
				.form_btn{
					cursor: pointer;
					width: 66px;
					height: 24px;
					border-radius: 18px;
					font-size: 12px;
				}
				.hasBg{
					color: #ffffff;
					background-color: #81D8D1;
				}
				.noBg{
					color: #81D8D1;
					background-color: #ffffff;
				}
			}
		}
	}
</style>