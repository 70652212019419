export const jobUndergoRules = {
	company_title: {
		required: true,
		message: '请填写公司名称',
		trigger: 'change'
	},
	company_position: {
		required: true,
		message: '请填写职位',
		trigger: 'change'
	},
	sjd: {
		required: true,
		message: '请选择时间段',
		trigger: 'change'
	},
	content: {
		required: true,
		message: '请填写工作内容',
		trigger: 'change'
	},
}