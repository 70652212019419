<template>
	<div class="userInfoAdd flex p-tb-20" id="userInfo">
		<div class="userInfoAdd_left br-10" v-loading="loading">
			<el-form ref="form" :model="form" label-position="top">
				<el-row>
					<el-col :span="10" :offset="1">
						<el-form-item label="姓名">
						    <el-input disabled v-model="form.username" placeholder="请填写您的姓名"></el-input>
						  </el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="联系电话">
						    <el-input disabled v-model="form.mobile" placeholder="请填写联系电话"></el-input>
						  </el-form-item>
					</el-col>
					<el-col :span="10" :offset="1">
						<el-form-item label="出生年月">
						  <el-input disabled v-model="form.birthday"></el-input>
						  </el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="性别">
						    <el-select disabled v-model="form.sex" placeholder="请选择">
						        <el-option
						          v-for="item in sexList"
						          :key="item.value"
						          :label="item.label"
						          :value="item.value">
						        </el-option>
						      </el-select>
						  </el-form-item>
					</el-col>
					<el-col :span="10" :offset="1">
						<el-form-item label="现居地">
						    <el-input disabled v-model="form.address" placeholder="请填写您的现居地"></el-input>
						  </el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="户籍地">
						    <el-input v-model="form.register" placeholder="请填写您的户籍地"></el-input>
						  </el-form-item>
					</el-col>
					<el-col :span="22" :offset="1">
						<el-form-item label="详细地址">
						    <el-input type="textarea" v-model="form.info_address" placeholder="请填写您的详细地址"></el-input>
						  </el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="form_bot flex just-end p-r-20">
				<div class="form_btn f-cen noBg" @click="cancle">
					<span>取消</span>
				</div>
				<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
					<span>完成</span>
				</div>
			</div>
		</div>
		<div class="userInfo_avatar m-l-10">
			<img @click="chooseImg" class="br-10" :src="image(form.avatar)" alt="">
		</div>
	</div>
</template>

<script>
import {sexArr} from "../../common.js"
import config from "@/config/config.js"
	export default{
		props:{
			userData:{
				type:Object,
				default:{}
			}
		},
		watch:{
			userData:{
				handler(val){
					this.form=JSON.parse(JSON.stringify(val));
					const _form = this.form;
					if(!_form.nickname){
						_form.nickname=_form.username;
					}
					if(!_form.birthday){
						_form.birthday=_form.birthday_year+'-'+_form.birthday_month
					}
					if(!_form.gender){
						_form.gender=_form.sex;
					}
					if(!_form.avatar){
						_form.avatar=_form.head_img;
					}
				},
				deep:true,
				immediate: true
			}
		},
		data() {
			return{
				form:{},
				sexList:sexArr,
				loading:false
			}
		},
		methods:{
			chooseImg() {
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.chooseImg();
			},
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			image(img){
				if(!img) return
				const _url = config.imgURL+img
				return _url
			},
			// 提交
			async submit(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				const _form = this.form;
				const _birthArr = _form.birthday.split('-');
				let data = {
					username:_form.nickname,
					head_img:_form.avatar,
					sex:_form.gender,
					mobile:_form.mobile,
					birthday_year:_birthArr[0],
					birthday_month:_birthArr[1],
					email:_form.email,
					identity:_form.identity,
					register:_form.register,
					address:_form.address,
					info_address:_form.info_address,
					political:_form.political
				}
				const res = await _parent.handleEdit('info_json',data);
				if(res){
					this.loading=false;
					this.cancle();
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.userInfoAdd{
		flex-wrap: nowrap;
	}
	.userInfoAdd_left{
		padding-top: 20px;
		padding-bottom: 17px;
		width: 78%;
		background-color: #f7f7f7;
		.el-col{
			/deep/.el-form-item{
				margin-bottom: 18px;
				.el-form-item__label{
					padding-bottom: 0 !important;
					line-height: 30px;
					color: #333333;
				}
			}
			/deep/.el-input__inner{
				border: none;
				border-radius: 6px;
				height: 35px;
			}
			/deep/.el-date-editor{
				width: auto;
			}
			/deep/.el-textarea__inner{
				border: none;
				border-radius: 6px;
			}
		}
		
	}
	.userInfo_avatar{
		img{
			object-fit: cover;
			cursor: pointer;
			width: 140px;
			height: 140px;
		}
	}
	.form_bot{
		.form_btn{
			cursor: pointer;
			width: 66px;
			height: 24px;
			border-radius: 18px;
			font-size: 12px;
		}
		.hasBg{
			color: #ffffff;
			background-color: #81D8D1;
		}
		.noBg{
			color: #81D8D1;
			background-color: #ffffff;
		}
	}
</style>