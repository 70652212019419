<template>
	<div class="home">
		<el-container>
			<!-- 头部 -->
			<el-header>
				<Header />
			</el-header>
			<el-main>
				<el-row>
					<el-col :span="16" :offset="4">
						<!-- 空白行 -->
						<div style="height: 20px;"></div>

						<div class="home_body">
							<!-- 目录 -->
							<Directory :dataInfo="directoryList" />
							<!-- 我的在线简历 -->
							<div class="home_box bg_f b-s br-20 m-r-10">
								<div class="home_boxTitle flex ali-cen p-l-30">
									<span>我的在线简历</span>
								</div>
								<div class="home_content p-lr-20" v-loading="loading" v-if="resumeLength">
									<!-- 添加个人信息 -->
									<user-info-add :userData="myInfo" v-if="editType=='userInfo'" />
									<!-- 个人信息 -->
									<user-info :userData="myInfo" v-else />

									<!-- 空白行 -->
									<div style="height: 10px;"></div>
									<!-- 添加求职意向 -->
									<job-intention-add :intentionData="handleData(dataInfo.job_intention_json)"
										v-if="editType=='JobIntention'" />
									<!-- 求职意向 -->
									<job-intention v-else :intentionData="handleData(dataInfo.job_intention_json)" />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加在校情况 -->
									<school-situation-add :schoolData="handleData(dataInfo.school_json)"
										v-if="editType=='schoolSituation'" />
									<!-- 在校情况 -->
									<school-situation v-else :schoolData="handleData(dataInfo.school_json)" />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加教育经历 -->
									<educate-undergo-add :educateData="handleData(dataInfo.education_json)"
										v-if="editType=='educateUndergo'" />
									<!-- 教育经历 -->
									<educate-undergo :educateData="handleData(dataInfo.education_json)" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加工作经历 -->
									<job-undergo-add :jobData="handleData(dataInfo.work_json)"
										v-if="editType=='jobUndergo'" />
									<!-- 工作经历 -->
									<job-undergo :jobData="handleData(dataInfo.work_json)" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加实习经历 -->
									<practice-undergo-add :practiceData="handleData(dataInfo.practice_json)"
										v-if="editType=='practiceUndergo'" />
									<!-- 实习经历 -->
									<practice-undergo :practiceData="handleData(dataInfo.practice_json)" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加项目经历 -->
									<project-undergo-add :projecteData="handleData(dataInfo.project_json)"
										v-if="editType=='projectUndergo'" />
									<!-- 项目经历 -->
									<project-undergo :projecteData="handleData(dataInfo.project_json)" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加专业技能及荣誉 -->
									<my-skill-add :skillData="dataInfo.speciality_text" v-if="editType=='mySkill'" />
									<!-- 专业技能及荣誉 -->
									<my-skill :skillData="dataInfo.speciality_text" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加自我评价 -->
									<self-evaluate-add :selfData="dataInfo.self_text" v-if="editType=='selfEvaluate'" />
									<!-- 自我评价 -->
									<self-evaluate :selfData="dataInfo.self_text" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
									<!-- 添加其他 -->
									<other-value-add :otherData="dataInfo.other_text" v-if="editType=='otherValue'" />
									<!-- 其他  -->
									<other-value :otherData="dataInfo.other_text" v-else />

									<!-- 空白行 -->
									<div style="height: 30px;"></div>
								</div>
								<div class="home_content p-lr-20 f-cen" v-loading="loading" v-else>
									<span>请先在小程序中新建一份简历吧~</span>
								</div>
							</div>
							<!-- 简历管理 -->
							<ResumeManage :list="resumeList" />
						</div>
					</el-col>
				</el-row>
			</el-main>
		</el-container>
		<!-- 隐藏的 input 元素 -->
		<input type="file" ref="fileInput" accept="image/*" style="display: none">
	</div>
</template>

<script>
	import Header from "@/components/Header.vue"
	import Directory from "@/components/Directory.vue"
	import ResumeManage from "@/components/ResumeManage.vue"
	import UserInfo from "./components/userInfo/index.vue"
	import UserInfoAdd from "./components/userInfo/add.vue"
	import JobIntention from "./components/JobIntention/index.vue"
	import JobIntentionAdd from "./components/JobIntention/add.vue"
	import educateUndergo from "./components/educateUndergo/index.vue"
	import educateUndergoAdd from "./components/educateUndergo/add.vue"
	import jobUndergo from "./components/jobUndergo/index.vue"
	import jobUndergoAdd from "./components/jobUndergo/add.vue"
	import practiceUndergo from "./components/practiceUndergo/index.vue"
	import practiceUndergoAdd from "./components/practiceUndergo/add.vue"
	import projectUndergo from "./components/projectUndergo/index.vue"
	import projectUndergoAdd from "./components/projectUndergo/add.vue"
	import mySkill from "./components/mySkill/index.vue"
	import mySkillAdd from "./components/mySkill/add.vue"
	import selfEvaluate from "./components/selfEvaluate/index.vue"
	import selfEvaluateAdd from "./components/selfEvaluate/add.vue"
	import otherValue from "./components/otherValue/index.vue"
	import otherValueAdd from "./components/otherValue/add.vue"
	import schoolSituation from "./components/schoolSituation/index.vue"
	import schoolSituationAdd from "./components/schoolSituation/add.vue"
	import {
		directoryArr,
		handleData,
		resumeUserArr
	} from "./common.js"
	import {
		getResumeDetail,
		getPositionList,
		editResume,
		userInfo,
		getMyResumeList,
		getUserInfo,
		upload
	} from "@/api/api.js"
	export default {
		components: {
			Header,
			Directory,
			ResumeManage,
			UserInfo,
			UserInfoAdd,
			JobIntention,
			JobIntentionAdd,
			educateUndergo,
			educateUndergoAdd,
			jobUndergo,
			jobUndergoAdd,
			practiceUndergo,
			practiceUndergoAdd,
			projectUndergo,
			projectUndergoAdd,
			mySkill,
			mySkillAdd,
			selfEvaluate,
			selfEvaluateAdd,
			otherValue,
			otherValueAdd,
			schoolSituation,
			schoolSituationAdd
		},
		data() {
			return {
				directoryList: directoryArr,
				control: true,
				// 简历详情
				dataInfo: {},
				// 打开哪一个模块
				editType: '',
				// 编辑经历的类型或下标
				undergoIndex: null,
				// 用户信息
				userInfo: {},
				// 简历列表
				resumeList: [],
				// 简历id
				resumeId: null,
				loading: false
			}
		},
		created() {
			this.getResumeList();
			this.getUserInfo();
		},
		mounted() {
			this.$refs.fileInput.addEventListener('change', () => {
				const file = this.$refs.fileInput.files[0];
				if (file) {
					console.log("已选择的图片文件:", file);
					// 在这里可以进行进一步的处理，比如上传到服务器或者展示在页面上
					const fullscreenLoading = this.$loading({
						lock: true,
						text: '上传中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					let formData = new FormData();
					formData.append('file', file);
					upload(formData).then(imgRes => {
						const _url = imgRes.data.url;
						let _valueObj = JSON.parse(JSON.stringify(this.myInfo));
						_valueObj.head_img = _url;
						let data = {
							resume_id: this.dataInfo.id,
							resume_field_key: 'info_json',
							resume_field_value: JSON.stringify(_valueObj)
						}
						editResume(data).then(res => {
							fullscreenLoading.close();
							this.$message({
								type: 'success',
								message: '修改成功'
							});
							this.getInfo();
						})
					})
				}
			});
		},
		computed: {
			myInfo() {
				let _info = {};
				if (this.dataInfo.info_json && this.dataInfo.info_json != '[]') {
					_info = JSON.parse(this.dataInfo.info_json);
				}
				for (let key of resumeUserArr) {
					_info[key] = this.userInfo[key];
				}
				return _info
			},
			resumeLength() {
				const _length = this.resumeList.length;
				return _length
			}
		},
		methods: {
			chooseImg() {
				this.$refs.fileInput.click(); // 触发 input 元素的点击事件
			},
			// 更换简历编辑
			changeResume(id) {
				this.resumeId = id;
				this.getInfo();
			},
			// 获取简历列表
			getResumeList(del = false) {
				getMyResumeList().then(res => {
					const _arr = res.data;
					this.resumeId = _arr[0].id;
					this.resumeList = _arr;
					if (del) return
					this.getInfo();
				})
			},
			// 获取用户信息
			getUserInfo() {
				getUserInfo().then(res => {
					const _obj = res.data;
					this.userInfo = _obj;
				})
			},
			// 获取简历详情
			getInfo() {
				this.loading = true;
				getResumeDetail({
					resume_id: this.resumeId
				}).then(res => {
					setTimeout(() => {
						this.loading = false;
					}, 100)
					const _obj = res.data;
					this.dataInfo = _obj;
				}).catch(err => {
					this.loading = false;
				})
			},
			// 处理json
			handleData(data) {
				if (!data) return
				const _data = JSON.parse(data);
				return _data
			},
			openEdit(type, index) {
				console.log('index: ', index);
				this.editType = type;
				this.undergoIndex = index;
			},
			// 处理含有下标的数据
			handleIndex(key, value) {
				let _value;
				let _list = [];
				if (this.dataInfo[key] && this.dataInfo[key] != '[]' && this.dataInfo[key] != 'null') {
					console.log('key: ', key);
					_list = JSON.parse(this.dataInfo[key]);
				}
				if (this.undergoIndex == 'add') {
					_list.push(value);
				} else {
					_list.splice(this.undergoIndex, 1, value);
				}
				_value = JSON.stringify(_list);
				return _value
			},
			// 保存修改
			handleEdit(key, value) {
				console.log('value: ', value);
				let _value = null;
				// 如果有下标
				if (this.undergoIndex || this.undergoIndex == 0) {
					_value = this.handleIndex(key, value);
				}
				return new Promise((resolve, reject) => {
					let data = {
						resume_id: this.dataInfo.id,
						resume_field_key: key,
						resume_field_value: _value ? JSON.stringify(_value) : JSON.stringify(JSON.stringify(
							value))
					}
					if (key == 'speciality_text' || key == 'self_text') {
						data.resume_field_value = JSON.parse(data.resume_field_value);
					}
					console.log('data: ', data);
					editResume(data).then(res => {
						this.getInfo();
						resolve(true)
					})
				})
			}
		}

	}
</script>

<style lang="less" scoped>
	.el-header {
		padding: 0;
		background-color: #81D8D1FF;
	}

	.el-main {
		padding: 0;
		height: calc(100vh - 60px);
		background-image: url('/src/static/image/homeBg.png');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.home_box {
		display: inline-block;
		vertical-align: top;
		width: 65%;
		height: calc(100vh - 100px);

		.home_content {
			height: calc(100vh - 150px);
			overflow: auto;

		}

		.home_boxTitle {
			height: 50px;
			color: #333333FF;
			background-image: url('/src/static/image/bgimg2.png');
			background-repeat: no-repeat;
			background-size: cover;
			border-radius: 20px 20px 0 0;
		}
	}
</style>