<template>
	<div class="jobUndergoAdd" id="projectUndergo" v-loading="loading">
		<div class="p-l-10">
			<my-title title="项目经历" add />
		</div>
		<div class="jobUndergoAdd_box br-10">
			<div class="jobUndergoAdd_form">
				<el-form ref="form" :model="form" label-position="top" :rules="rules">
					<el-row>
						<el-col :span="10" :offset="1">
							<el-form-item label="项目名称" prop="title">
							    <el-input v-model="form.title" placeholder="请填写项目名称"></el-input>
							  </el-form-item>
						</el-col>
						<el-col :span="10" :offset="2">
							<el-form-item label="项目角色" prop="role">
							   <el-input v-model="form.role" placeholder="请填写项目角色"></el-input>
							  </el-form-item>
						</el-col>
						<el-col :span="10" :offset="1">
							<el-form-item label="时间段" prop="sjd">
							 <el-date-picker
							 value-format="yyyy-MM"
							       v-model="form.sjd"
							       type="monthrange"
							       range-separator="-"
							       start-placeholder="开始时间"
							       end-placeholder="结束时间">
							     </el-date-picker>
							  </el-form-item>
						</el-col>
						<el-col :span="22" :offset="1">
							<el-form-item label="项目内容" prop="content">
							    <Editor v-model="form.content" />
							  </el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="form_bot flex just-end p-r-20">
					<div class="form_btn f-cen noBg" @click="cancle">
						<span>取消</span>
					</div>
					<div class="form_btn f-cen m-l-10 hasBg" @click="submit">
						<span>完成</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	import Editor from "@/components/Editor.vue"
	import {projectUndergoRules} from "./common.js"
	export default{
		components:{
			myTitle,
			Editor
		},
		props:{
			projecteData:{
				type:Array,
				default:()=>[]
			}
		},
		watch:{
			projecteData:{
				handler(val){
					if(val && Array.isArray(val) && val.length === 0) return
					const _parent = this.$parent.$parent.$parent.$parent.$parent;
					if(_parent.undergoIndex=='add') return
					this.form = val[_parent.undergoIndex];
					const _form = this.form;
					this.$set(this.form,'sjd',[_form.start,_form.end]);
				},
				deep: true,
				immediate: true
			}
		},
		data() {
			return{
				form:{},
				loading:false,
				rules:projectUndergoRules
			}
		},
		methods:{
			// 取消
			cancle(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit(null);
			},
			async submit(){
				const valid = await this.$refs.form.validate();
				if(!valid) return
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				this.loading=true;
				this.form.start=this.form.sjd[0];
				this.form.end=this.form.sjd[1];
				const res = await _parent.handleEdit('project_json',this.form);
				if(!res) return
				this.loading=false;
				this.$message({
					message: '保存成功',
					type: 'success'
				})
				this.cancle();
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.jobUndergoAdd_box{
		width: 100%;
		background-color: #F7F7F7;
		margin-top: 10px;
		padding-bottom: 18px;
		padding-top: 20px;
		.el-col{
			/deep/.el-form-item{
				margin-bottom: 18px;
				.el-form-item__label{
					padding-bottom: 0 !important;
					line-height: 30px;
					color: #333333;
				}
			}
			/deep/.el-input__inner{
				border: none;
				border-radius: 6px;
				height: 35px;
			}
			/deep/.el-date-editor{
				width: auto;
			}
			/deep/.el-textarea__inner{
				border: none;
				border-radius: 6px;
			}
		}
		.jobUndergoAdd_form{
			width: 78%;
		}
		.form_bot{
			.form_btn{
				cursor: pointer;
				width: 66px;
				height: 24px;
				border-radius: 18px;
				font-size: 12px;
			}
			.hasBg{
				color: #ffffff;
				background-color: #81D8D1;
			}
			.noBg{
				color: #81D8D1;
				background-color: #ffffff;
			}
		}
	}
</style>