<template>
	<div class="selfEvaluate" id="selfEvaluate">
		<div class="p-l-10">
			<my-title title="其他" />
		</div>
		<div class="selfEvaluate_box m-t-10 br-10" @mouseenter="checked=true" @mouseleave="checked=false" :class="{'bg_f7':checked}">
			<!-- 操作栏 -->
			<div class="selfEvaluate_operate flex just-end" v-show="checked">
				<div class="flex ali-cen" @click="openEdit">
					<img src="@/static/image/edit.png" alt="">
					<span>编辑</span>
				</div>
				<!-- <div class="flex ali-cen m-l-15">
					<img src="@/static/image/trash.png" alt="">
					<span>删除</span>
				</div> -->
			</div>
			<!-- 内容 -->
			<div class="ts-14 c-3">
				<div v-html="otherData"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default{
		components:{
			myTitle
		},
		props:{
			otherData:{
				type:String,
				default:''
			}
		},
		data() {
			return{
				checked:false
			}
		},
		methods:{
			openEdit(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('otherValue');
			}
		}
	}
</script>

<style lang="less" scoped>
	.selfEvaluate_box{
		box-sizing: border-box;
		padding: 10px 10px 20px 20px;
		cursor: pointer;
		.selfEvaluate_operate{
			div{
				color: #81D8D1;
				font-size: 14px;
				img{
					width: 18px;
					height: 18px;
					margin-right: 5px;
				}
			}
		}
	}
</style>