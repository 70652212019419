<template>
	<div class="login page_h psr">
		<div class="login_box psa">
			<!-- 标题 -->
			<img class="login_title m-a" src="@/static/image/loginTitle.png" alt="">
			<div class="login_card bg_f br-20 m-t-30 f-cen b-s">
				<img class="login_avatar br" src="@/static/image/loginAvatar.png" alt="">
				<div class="login_input m-t-40">
					<el-input v-if="!loginCode" placeholder="请输入手机号" v-model="form.account" clearable></el-input>
					<el-input v-else placeholder="请输入手机号" v-model="form.mobile" clearable></el-input>
				</div>
				<div class="login_input m-t-20">
					<el-input type="password" v-if="!loginCode" placeholder="请输入密码" v-model="form.password" clearable></el-input>
					<el-input v-else placeholder="请输入验证码" v-model="form.captcha" clearable>
						<template #suffix v-if="loginCode">
							<div class="login_code bg_c f-cen" @click="sendCode">
								<span class="tc-w">{{codeInterval ? codeTime+'秒之后可重新发送':'获取验证码'}}</span>
							</div>
						</template>
					</el-input>
				</div>
				<div class="login_change m-t-20 tc page-c">
					<span @click="loginCode=!loginCode">{{loginCode ? '密码登录':'验证码登录'}}</span>
				</div>
				<div class="login_btn br-20 bg_c f-cen m-t-40" @click="loginSub">
					<span class="tc-w tb">登录</span>
				</div>
			</div>
		</div>
		<!-- 备案号 -->
		<div class="login_bot psa">
			备案号：<a target="_blank" href="https://beian.miit.gov.cn">鄂ICP备2024038960号-2</a>
		</div>
	</div>
</template>

<script>
	import {login,send,mobilelogin} from "@/api/login.js"
	export default {
		data() {
			return {
				form: {},
				// 是否验证码登录方式
				loginCode:false,
				codeTime:60,
				codeInterval:null
			}
		},
		methods:{
			// 发送验证码
			sendCode(){
				if (!this.form.mobile) {
					this.$message.error('请输入手机号');
					return
				}
				if (this.codeInterval) {
					this.$message.error('验证码已发送，请勿重复操作');
					return
				}
				let data = {
					mobile:this.form.mobile
				}
				send(data).then(res=>{
					this.$message.success('发送成功');
					this.codeInterval = setInterval(() => {
						if (this.codeTime > 1) {
							this.codeTime--;
							console.log('this.codeTime: ', this.codeTime);
						} else {
							clearInterval(this.codeInterval);
							this.codeInterval = null;
							this.codeTime = 60;
						}
					}, 1000)
				})
			},
			loginSub(){
				const valid = this.handleValid();
				if(!valid) return
				console.log(11111111,valid);
				if(!this.loginCode){
					this.passLogin();
				}else{
					this.codeLogin();
				}
			},
			// 验证
			handleValid(){
				const _arr = [];
				const _form = this.form;
				if(this.loginCode){
					if(!_form.mobile){
						this.$message.error('请输入手机号');
						_arr.push(false)
						return
					}
					if(!_form.captcha){
						this.$message.error('请输入验证码');
						_arr.push(false)
						return
					}
				}else{
					if(!_form.account){
						this.$message.error('请输入账号');
						_arr.push(false)
						return
					}
					if(!_form.password){
						this.$message.error('请输入密码');
						_arr.push(false)
						return
					}
				}
				if(_arr.includes(false)) return false
				return true
			},
			// 验证码登录
			codeLogin(){
				mobilelogin(this.form).then(res=>{
					this.successEvent(res);
				})
			},
			// 密码登录
			passLogin(){
				login(this.form).then(res=>{
					this.successEvent(res);
				})
			},
			successEvent(res){
				this.$message({
					type:'success',
					message:'登录成功',
				})
				let _userInfo = res.data.userinfo;
				localStorage.setItem('userInfo',JSON.stringify(_userInfo));
				localStorage.setItem('token',_userInfo['0'].token);
				this.$router.push('home')
			}
		}
	}
</script>

<style lang="less" scoped>
	.login {
		background-image: url('/src/static/image/loginBg.png');
		background-size: cover;
		background-repeat: no-repeat;
		
	}
	.login_bot{
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		font-size: 14px;
		a{
			cursor: pointer;
			text-decoration: none;
			color: #000000;
		}
	}

	.login_box {
		top: 20vh;
		left: 50%;
		transform: translateX(-50%);
		width: 24vw;

		.login_title {
			display: block;
			width: 16vw;
			height: auto;
		}

		.login_card {
			width: 100%;
			padding-top: 57px;
			padding-bottom: 38px;
			flex-direction: column;

			.login_avatar {
				width: 100px;
				height: 100px;
			}

			.login_input {
				width: 70%;
				/deep/.el-input__inner{
					border: none;
					background-color: #f7f7f7;
					border-radius: 20px;
				}
				.login_code{
					cursor: pointer;
					padding: 6px 8px;
					border-radius: 17px;
					margin-top: 4px;
					font-size: 14px;
				}
			}
			.login_change{
				font-size: 16px;
				span{
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.login_btn{
				width: 70%;
				height: 40px;
				cursor: pointer;
			}
		}
	}
</style>
<style>
	
</style>