<template>
	<div class="JobIntention" id="JobIntention" @mouseenter="checked=true" @mouseleave="checked=false">
		<div class="p-l-10">
			<my-title title="在校情况" />
		</div>
		<div class="JobIntention_box br-10 psr" :class="{'bg_f7':checked}">
			<div class="c-3">
				校内荣誉/奖项：{{schoolData.honor}}
			</div>
			<div class="c-3 m-t-15">
				校内职务：{{schoolData.jobs}}
			</div>
			<div class="resume_edit page-c flex psa" v-show="checked" @click.stop="openEdit">
				<img class="resume_editIcon" src="@/static/image/edit.png" alt="">
				<span>编辑</span>
			</div>
		</div>
	</div>
</template>

<script>
	import myTitle from "@/components/myTitle.vue"
	export default{
		components:{
			myTitle
		},
		props:{
			schoolData:{
				type:Object,
				default:()=>{}
			}
		},
		data(){
			return{
				checked:false
			}
		},
		methods:{
			openEdit(){
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('schoolSituation');
			}
		}
	}
</script>

<style lang="less" scoped>
	.JobIntention{
		cursor: pointer;
	}
	.JobIntention_box{
		box-sizing: border-box;
		padding: 15px 10px 13px 10px;
		margin-top: 5px;
		// background-color: #F7F7F7;
		width: 78%;
		div{
			font-size: 14px;
		}
		li{
			list-style: disc;
		}
	}
</style>