<template>
	<div class="userInfo p-tb-20 flex" id="userInfo" @mouseenter="checked=true" @mouseleave="checked=false">
		<div class="userInfo_left">
			<div class="userInfo_title c-3 p-l-10">
				{{userData.nickname || userData.username}}
			</div>
			<div class="userInfo_box br-10 psr" :class="{'bg_f7':checked}">
				<ul class="flex">
					<li>联系电话 {{userData.mobile}}</li>
					<li class="m-l-50">性别 {{userData.sex==1 ? '男':'女'}}</li>
				</ul>
				<ul class="flex m-t-15">
					<li>出生年月 {{userData.birthday || '暂无'}}</li>
					<li class="m-l-50">现居地 {{userData.address || '暂无'}}</li>
					<li class="m-l-50">户籍地 {{userData.register || '暂无'}}</li>
				</ul>
				<ul class="flex m-t-15">
					<li>详细地址 {{userData.info_address || '暂无'}}</li>
				</ul>
				<div class="resume_edit page-c flex psa" v-show="checked" @click.stop="openEdit">
					<img class="resume_editIcon" src="@/static/image/edit.png" alt="">
					<span>编辑</span>
				</div>
			</div>
		</div>
		<div class="userInfo_avatar p-r-10 m-l-10">
			<img @click="chooseImg" class="br-10" :src="image(userData.head_img)" alt="">
		</div>
	</div>
</template>

<script>
	import config from "@/config/config.js"
	export default {
		props: {
			userData: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				checked: false
			}
		},
		methods: {
			image(img) {
				if (!img) return
				const _url = config.imgURL + img
				return _url
			},
			openEdit() {
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.openEdit('userInfo');
			},
			chooseImg() {
				const _parent = this.$parent.$parent.$parent.$parent.$parent;
				_parent.chooseImg();
			}
		}
	}
</script>

<style lang="less" scoped>
	.userInfo {
		flex-wrap: nowrap;
		cursor: pointer;

		.userInfo_title {
			font-size: 22px;
		}

		.userInfo_left {
			width: 78%;
		}

		.userInfo_box {
			width: 100%;
			margin-top: 8px;
			box-sizing: border-box;
			padding: 12px 10px 13px 10px;

			li {
				color: #666666;
				font-size: 14px;
				list-style: disc;
				margin-left: 20px;
			}

		}

		.userInfo_avatar {
			img {
				width: 140px;
				height: 140px;
				object-fit: cover;
			}
		}
	}
</style>