const env = process.env.NODE_ENV

const config = {
	development:{
		baseURL:"/api"
	},
	production:{
		baseURL:"https://admin.whjuncai.com"
	}
}

export default{
	baseURL:config[env].baseURL,
	imgURL:"https://whjuncai-1318664441.cos.ap-shanghai.myqcloud.com"
}